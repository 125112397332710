import { SvgHeart } from '@icons/designSystemIcons/SvgHeart';
import ROUTES from '@utils/routes';
import { Button } from 'designSystem/Buttons/Buttons';
import { Container, Flex } from 'designSystem/Layouts/Layouts';
import { Text } from 'designSystem/Texts/Texts';

export default function PromoTopBar() {
  return (
    <div className="bg-hg-red w-full overflow-hidden relative py-3">
      <Container className="max-w-[600px]">
        <Flex className="justify-between items-center">
          <Flex>
            <SvgHeart className="shrink-0 mr-2 h-4 w-4 md:h-6 md:w-6" />
            <Text disableAnimation>
              <span className="text-hg-skin md:text-lg font-bold font-gtUltra">
                Regala(te) una piel radiante
              </span>{' '}
            </Text>
          </Flex>
          <Button
            id={'tmevent_promo_header_bar'}
            href={`${ROUTES.aestheticTreatments}/glow-box`}
            size="sm"
            customStyles={'bg-hg-red15 hover:bg-white/15 py-1 md:py-2 h-auto'}
          >
            Ver más
          </Button>
        </Flex>
      </Container>
    </div>
  );
}
