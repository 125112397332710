'use client';

import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Clinic } from '@interface/clinic';
import CheckHydration from '@utils/CheckHydration';
import { useGlobalPersistedStore } from 'app/stores/globalStore';
import { fetchClinics } from 'app/utils/fetch';
import SimpleAccordion from 'designSystem/Accordion/SimpleAccordion';
import { Flex } from 'designSystem/Layouts/Layouts';
import { isEmpty } from 'lodash';

export default function FooterSecondBlock() {
  const { clinics, setClinics } = useGlobalPersistedStore(state => state);

  useEffect(() => {
    async function initClinics() {
      const clinics = await fetchClinics();

      setClinics(clinics);
    }

    if (isEmpty(clinics)) {
      initClinics();
    }
  }, [clinics]);

  return (
    <CheckHydration>
      <Flex
        layout="col-left"
        className="gap-6 w-full md:w-1/4 text-xl font-semibold px-4 md:px-0 pb-6"
      >
        <SimpleAccordion trigger="Clínicas" isOpen={!isMobile}>
          <ul className="text-xs pt-4 font-normal flex flex-col gap-2">
            {clinics &&
              clinics.map((clinic: Clinic) => (
                <a
                  href={
                    '/clinica-medicina-estetica/' +
                    clinic.internalName.toLowerCase()
                  }
                  key={clinic.city}
                  id={'tmevent_footer'}
                >
                  <li>{clinic.city}</li>
                </a>
              ))}
          </ul>
        </SimpleAccordion>

        <SimpleAccordion trigger="Nosotrxs" isOpen={!isMobile}>
          <ul className="text-xs pt-4 font-normal flex flex-col gap-2">
            <a href="/quienes-somos" id={'tmevent_footer'}>
              <li>Quiénes somos</li>
            </a>
            <a href="/quienes-somos" id={'tmevent_footer'}>
              <li>Equipo médico</li>
            </a>
          </ul>
        </SimpleAccordion>
        <SimpleAccordion trigger="Privacidad" isOpen={!isMobile}>
          <ul className="text-xs pt-4 font-normal flex flex-col gap-2">
            <a href="/politica-de-privacidad" id={'tmevent_footer'}>
              <li>Política de privacidad</li>
            </a>
            <a href="/aviso-legal" id={'tmevent_footer'}>
              <li>Términos y condiciones</li>
            </a>
          </ul>
        </SimpleAccordion>
      </Flex>
    </CheckHydration>
  );
}
