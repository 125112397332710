import { SVGProps } from 'react';

export const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={25}
    width={24}
    fill="currentColor"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      d="M24.5 7.84814C24.5 14.4604 17.6359 14.6653 12.9365 21.7727C12.7361 22.0758 12.2595 22.0758 12.0635 21.7727C7.36414 14.6653 0.5 14.4604 0.5 7.84814C0.5 4.21119 3.6314 2 6.74053 2C9.17706 2 11.3107 3.25073 12.5 5.12897C13.6893 3.255 15.8229 2 18.2595 2C21.3686 2 24.5 4.21119 24.5 7.84814Z"
      fill="#FFE2CC"
    />
  </svg>
);
